$table-bg: transparent;

@import "../node_modules/bootstrap/scss/bootstrap";

.file-select::before {
  content: '1.';
  min-width: 25px;
  display: inline-block;
}

#root {
  height: 100%;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.active {
  color: rgb(108, 117, 125) !important;
}

.nav-link {
  color: rgb(0, 0, 0);
}

.font-small {
  font-size: 0.8rem;
}

.svg-small {
  font-size: 1%;
  font-family: Arial, Helvetica, sans-serif;
}

.pointer {
  cursor: pointer;
}


.hover-bg-secondary:hover {
  background-color: #e9ecef;
}

body {
  overflow-y: scroll;
}

/* Login */

.slim-form {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  //bbackground-color: #f5f5f5;
  min-height: 100%;
}

.form-signin, .form-register {
  max-width: 330px;
  padding: 15px;
  height: 100%;
}

.form-default {
  padding: 15px;
  height: 100%;
}

.form-signin .form-floating:focus-within, .form-register .form-floating:focus-within, .form-default .form-floating:focus-within {
  z-index: 2;
}


.led-text {
  font-family: noto;
  font-size: 32px;

  background-color: black !important;
  border: 1px solid #ced4da;
  line-height: 110%;
  overflow: hidden;
  display: inline-block;
}

.led-text-red {
  color: #ff0000 !important;
}

.rte-text-large {
  font-size: 2.8rem !important;
}

.rte-font-wide {
  font-family: noto-bold;

  // text-decoration: underline overline;
  // text-decoration-style: dotted;
  letter-spacing: 10px;
  text-shadow: 2px 0;

  // display: inline-block;
  // transform: scaleX(1.6);
  // pointer-events: none;
  // transform-origin: left; //add this line
}

.rte-text-green {
  color: #00ff00 !important;
}

.rte-text-red {
  color: #ff0000 !important;
}

.rte-text-yellow {
  color: #ffff00 !important;
}

.rte-text-flash-on .rte-text-flash {
  opacity: 1;

}



.rte-text-flash-off .rte-text-flash {
  opacity: 0;

}


@font-face {
  font-family: 'noto-bold';
  src: url('./fonts/Noto_Sans_Mono/NotoSansMono-ExtraBold.otf') format('opentype'),
  url('./fonts/Noto_Sans_Mono/NotoSansMono-ExtraBold.woff2') format('woff2'),
  url('./fonts/Noto_Sans_Mono/NotoSansMono-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'noto';
  src: url('./fonts/Noto_Sans_Mono/NotoSansMono-Light.otf') format('opentype'),
  url('./fonts/Noto_Sans_Mono/NotoSansMono-Light.woff2') format('woff2'),
  url('./fonts/Noto_Sans_Mono/NotoSansMono-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



.fnt-display-preview-tile {
  font-size:120%;
  line-height: 100%;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}


.display-preview-tile-all:hover {
    background-color: var(--bs-gray-300) !important;
}







